import {
  DiscountConsumer,
  ProductGroupCardDiscountDisplay,
  ProductOrderButton,
  ProductType as LegoProductType,
  RecipeWeeks,
  useDiscountContext,
} from '@mfb/cookbook';
import {ActionButtonProps, TrackGtm} from '@mfb/lego';
import classnames from 'classnames';
import {first, flatMap} from 'lodash';
import * as React from 'react';

import {ProductType} from '../../../gatsbyLib/data/CoreApiClient';
import {SiteMetadataQueryModel} from '../../contentful/SiteMetadataQueryModel';
import {ProductDbModel} from '../../customGraphQlModels/ProductDbModel';
import {RecipeCollectionModel} from '../../customGraphQlModels/ProductRecipeCollectionModel';
import {getWeeksFromRecipeCollectionModel} from '../../util/getWeeksFromRecipeCollectionModel';
import {
  BufferTrackingType,
  getBufferTrackingDetails,
} from './getBufferTrackingDetails';

interface Props {
  product: ProductDbModel;
  siteMetadata: SiteMetadataQueryModel;
  bgToggle: boolean;
}

export const getPrimaryActionButtonForBuffer = (
  product: ProductDbModel,
  showProductPrice: boolean,
  gatewayUrl: string
): ActionButtonProps => ({
  label: `Buy Now${
    showProductPrice
      ? ` ($${(product.price + product.deliveryPrice).toFixed(2)})`
      : ''
  }`,
  url: `${gatewayUrl}/order/${product.sku}?inWeekSale=true`,
  dataCategory: 'product',
  dataLabel: `buffer-stock-${product.sku}`,
  dataAction: 'add',
});

export const BufferRecipeWeeks: React.FunctionComponent<Props> = ({
  product,
  siteMetadata,
  bgToggle,
}) => {
  const discountContext = useDiscountContext();
  const {discount} = discountContext;

  const getOrderButtonClickTracking = React.useCallback(
    () =>
      getBufferTrackingDetails(
        [product],
        discount,
        BufferTrackingType.OrderNow
      ),
    [discount, product]
  );

  const recipeCollections: Array<RecipeCollectionModel> = flatMap(
    product.recipeCollections,
    'collections'
  );

  const weeks = getWeeksFromRecipeCollectionModel(recipeCollections);

  // Buffer stock should show recipes for the upcoming week.
  const week = first(weeks);
  const recipeCollectionsForWeek = recipeCollections.filter(
    recipeCollection => recipeCollection.day === week
  );

  const filteredRecipeCollections: Array<RecipeCollectionModel> = recipeCollectionsForWeek.map(
    collection => ({
      ...collection,
      recipes: collection.recipes.filter(
        r => r.isDefaultChoice && r.surcharge === 0
      ),
    })
  );

  const isExtra =
    product.productType === ProductType.Extra ||
    product.productType === ProductType.OneOff ||
    product.productType === ProductType.Donation;

  if (recipeCollectionsForWeek.length > 0) {
    return (
      <div
        key={`${product.id}-${week}`}
        className={classnames('text-center', {
          'bg-secondary text-center': bgToggle,
        })}
      >
        <h3 className="pt-4">{product.name}</h3>
        <DiscountConsumer
          product={{
            ...product,
            price: product.price + product.deliveryPrice,
            productType: isExtra
              ? LegoProductType.Extra
              : LegoProductType.Primary,
          }}
          render={price => {
            const isDiscounted = price.discounted !== price.original;

            return (
              <>
                {isDiscounted && (
                  <div className="d-flex justify-content-center w-100">
                    <ProductGroupCardDiscountDisplay price={price} />
                  </div>
                )}
                <TrackGtm getEventDetails={getOrderButtonClickTracking}>
                  <ProductOrderButton
                    isBuffer={true}
                    sku={product.sku}
                    productType={
                      isExtra ? LegoProductType.Extra : LegoProductType.Primary
                    }
                    className="my-2"
                    actionButton={getPrimaryActionButtonForBuffer(
                      product,
                      !isDiscounted,
                      siteMetadata.gatewayUrl
                    )}
                  />
                </TrackGtm>
              </>
            );
          }}
        />
        <RecipeWeeks
          key={product.id}
          id={product.id}
          showWeekSelector={false}
          isRecipeModalEnabled={true}
          recipeCollections={filteredRecipeCollections}
          pageSectionVerticalSpaceClassName="py-4"
        />
      </div>
    );
  }

  return null;
};
