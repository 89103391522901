import {StockActionType, useDiscountContext, useStock} from '@mfb/cookbook';
import {
  ButtonCheckbox,
  Jumbotron,
  PeopleIcon,
  sendTrackingDetails,
  StickyHeader,
} from '@mfb/lego';
import {orderBy, sortBy, uniqBy} from 'lodash';
import * as React from 'react';
import {Spinner} from 'reactstrap';

import {SiteMetadataQueryModel} from '../../contentful/SiteMetadataQueryModel';
import {ProductDbModel} from '../../customGraphQlModels/ProductDbModel';
import {mfbScrollSmooth} from '../MFBScroll';
import {BufferRecipeWeeks} from './BufferRecipeWeeks';
import {
  BufferTrackingType,
  getBufferTrackingDetails,
} from './getBufferTrackingDetails';

interface Props {
  products: Array<ProductDbModel>;
  siteMetadata: SiteMetadataQueryModel;
  preSelectedSku?: string;
}

const jumbotronId = 'jumbo-last-minute-bags';
const jumbotronDescription =
  'Below are our bags currently available for delivery this Saturday, Sunday or Monday. Stock is very limited so get in quick.';

export const getPeopleOptions = (products: Array<ProductDbModel>) =>
  orderBy(
    uniqBy(
      products.map(p => ({value: p.numberOfPeopleToFeed, isDisabled: false})),
      'value'
    ),
    ['value'],
    ['asc']
  );

export const BufferList: React.FunctionComponent<Props> = ({
  products,
  siteMetadata,
  preSelectedSku,
}) => {
  const [{isLoading: isLoadingStock, bufferStock}, dispatch] = useStock();

  React.useEffect(() => {
    products.forEach(p =>
      dispatch({type: StockActionType.registerBuffer, sku: p.sku})
    );
  }, [dispatch, products]);
  const peopleOptions = getPeopleOptions(products);

  const preselectedProduct = React.useMemo(
    () =>
      preSelectedSku
        ? products.find(
            p => p.sku === preSelectedSku || p.itemNumber === preSelectedSku
          )
        : undefined,
    [preSelectedSku, products]
  );

  const preSelectedNumberOfPeopleToFeed =
    (preselectedProduct && preselectedProduct.numberOfPeopleToFeed) ||
    peopleOptions[0].value;

  const [
    selectedNumberOfPeopleToFeed,
    setNumberOfPeopleToFeed,
  ] = React.useState(preSelectedNumberOfPeopleToFeed);

  const onPeopleChange = (val: number) => {
    setNumberOfPeopleToFeed(val);
    mfbScrollSmooth(jumbotronId);
  };

  const filteredProducts = React.useMemo(() => {
    const stockLookup: Record<string, boolean> = {};
    bufferStock.forEach(bs => {
      stockLookup[bs.sku] = bs.hasStock;
    });

    let productsToShow = sortBy(
      products.filter(
        p => p.numberOfPeopleToFeed === selectedNumberOfPeopleToFeed
      ),
      // sort sold out bags to the bottom of the page
      p => !stockLookup[p.sku]
    );

    if (
      preselectedProduct &&
      preselectedProduct.numberOfPeopleToFeed === selectedNumberOfPeopleToFeed
    ) {
      productsToShow = productsToShow.filter(
        p => p.sku !== preselectedProduct.sku
      );

      return [preselectedProduct, ...productsToShow];
    }

    return productsToShow;
  }, [bufferStock, preselectedProduct, products, selectedNumberOfPeopleToFeed]);

  const PeopleSelector = () => (
    <div className="w-100 d-flex justify-content-center mt-2">
      <div className="d-flex align-items-center mr-4">
        <PeopleIcon className="mr-1" />
        <span className="text-nowrap">People to feed</span>
      </div>
      <ButtonCheckbox
        options={peopleOptions}
        onChange={onPeopleChange}
        selected={selectedNumberOfPeopleToFeed}
      />
    </div>
  );

  const discountContext = useDiscountContext();
  const {discount, isLoading} = discountContext;

  React.useEffect(() => {
    if (!isLoading) {
      const trackingDetails = getBufferTrackingDetails(
        filteredProducts,
        discount,
        BufferTrackingType.ProductList
      );

      sendTrackingDetails(trackingDetails);
    }
  }, [discount, filteredProducts, isLoading]);

  return (
    <>
      <Jumbotron
        id={jumbotronId}
        title="Available Bags"
        description={jumbotronDescription}
        splitOnMobile={false}
      >
        <div className="mb-4">
          <PeopleSelector />
        </div>
        <StickyHeader text={'Select a Bag'} actionItem={<PeopleSelector />} />
      </Jumbotron>
      {isLoadingStock ? (
        <div className="d-flex w-100 justify-content-center my-5">
          <Spinner color="primary" />
        </div>
      ) : (
        filteredProducts.map((p, i) => (
          <div key={p.id}>
            <BufferRecipeWeeks
              product={p}
              siteMetadata={siteMetadata}
              bgToggle={i % 2 === 0}
            />
          </div>
        ))
      )}
    </>
  );
};
