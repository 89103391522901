import {
  calculateDiscount,
  Discount,
  ProductType as LegoProductType,
} from '@mfb/cookbook';

import {ProductDbModel} from '../../customGraphQlModels/ProductDbModel';
import {ProductType} from '../../util/client';

export enum BufferTrackingType {
  ProductList = 'product_list',
  OrderNow = 'product_order_now',
}

interface ProductTrackingDetailsProps {
  product: ProductDbModel;
  discount: Discount;
  position: number;
  pathName?: string;
  quantity?: number;
}

const currencyCode = 'NZD';

const mapProductToProductTrackingDetails = (
  props: ProductTrackingDetailsProps
) => {
  const {product, discount, position, pathName, quantity} = props;
  const isExtra =
    product.productType === ProductType.Extra ||
    product.productType === ProductType.OneOff ||
    product.productType === ProductType.Donation;

  const category = isExtra ? 'Extras' : 'Bags';
  const {price} = calculateDiscount(discount, {
    ...product,
    productType: isExtra ? LegoProductType.Extra : LegoProductType.Primary,
  });

  const productTrackingDetails = {
    name: product.name || '',
    id: product.sku,
    price: price.discounted,
    category,
    list: pathName,
    quantity,
    position,
    dimension6: 'OneOff',
  };

  return productTrackingDetails;
};

const buildProductListTracking = (
  products: Array<ProductDbModel>,
  discount: Discount
) => {
  const pathName = document.location.pathname;

  const productTrackingDetails = products.map((product, position) =>
    mapProductToProductTrackingDetails({
      product,
      discount,
      position: position + 1,
      pathName,
    })
  );

  return {
    event: BufferTrackingType.ProductList,
    eventDetails: {
      category: 'Ecommerce',
      action: 'Product List',
    },
    ecommerce: {
      currencyCode,
      impressions: productTrackingDetails,
    },
  };
};

const buildProductOrderNowTracking = (
  products: Array<ProductDbModel>,
  discount: Discount
) => {
  const productTrackingDetails = products.map((product, position) =>
    mapProductToProductTrackingDetails({
      product,
      discount,
      position: position + 1,
      quantity: 1,
    })
  );

  const pathName = document.location.pathname;

  return {
    event: BufferTrackingType.OrderNow,
    eventDetails: {
      category: 'Ecommerce',
      action: 'AddToCart',
    },
    ecommerce: {
      currencyCode,
      add: {
        actionField: {
          list: pathName,
        },
        products: productTrackingDetails,
      },
    },
  };
};

export const getBufferTrackingDetails = (
  products: Array<ProductDbModel>,
  discount: Discount,
  trackingType: BufferTrackingType
) => {
  switch (trackingType) {
    case BufferTrackingType.ProductList:
      return buildProductListTracking(products, discount);
    case BufferTrackingType.OrderNow:
      return buildProductOrderNowTracking(products, discount);
  }
};
