import {graphql, StaticQuery} from 'gatsby';
import {orderBy} from 'lodash';
import * as React from 'react';

import {ProductType} from '../../../gatsbyLib/data/CoreApiClient';
import {ExternalPaths} from '../../contentful/PathRulesHoc';
import {RecipeInfoQueryModel} from '../../contentful/productCard/AllProductsQueryModel';
import {SiteQueryModel} from '../../contentful/SiteMetadataQueryModel';
import {getNodes} from '../../customGraphQlModels/Edges';
import {BufferList} from './BufferList';

export const SelfServeBuffer: React.FunctionComponent<ExternalPaths &
  SiteQueryModel> = ({siteMetadata}) => (
  <StaticQuery
    query={graphql`
      query {
        allProductDb(
          filter: {
            sku: {nin: ["default"]}
            brand: {in: ["MFB", "FS", "BB"]}
            availableFrequency: {ne: 4}
            recipeCollections: {elemMatch: {isLateSalesDisabled: {eq: false}}}
          }
        ) {
          edges {
            node {
              ...ProductDbFragment
            }
          }
        }
      }
    `}
    render={(data: RecipeInfoQueryModel) => {
      const orderedProducts = orderBy(
        getNodes(data.allProductDb),
        ['brand', 'name'],
        ['desc', 'asc']
      );
      const primaryProducts = orderedProducts.filter(
        o => o.productType === ProductType.Primary
      );

      let queryString;
      if (typeof window !== `undefined`) {
        queryString = window.location.search.substring(1);
      }

      const params = new URLSearchParams(queryString);
      const sku = params.get('sku') || '';

      return (
        <BufferList
          products={primaryProducts}
          siteMetadata={siteMetadata}
          preSelectedSku={sku}
        />
      );
    }}
  />
);
