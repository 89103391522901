import * as React from 'react';

import {SelfServeBuffer} from '../components/selfServeBuffer/SelfServeBuffer';
import {MetadataContentModel} from '../contentful/metadata/MetadataContentModel';
import {ExternalPaths, WithPathRules} from '../contentful/PathRulesHoc';
import {WithSiteMetadata} from '../contentful/SiteMetadataHoc';
import {SiteQueryModel} from '../contentful/SiteMetadataQueryModel';
import PageTemplate from '../templates/PageTemplate';

const LateSalesPageTemplate: React.FunctionComponent<ExternalPaths &
  SiteQueryModel> = props => {
  const description =
    'Bags currently available for delivery this Saturday, Sunday or Monday. Stock is very limited so get in quick.';

  const metadata: MetadataContentModel = {
    title: 'My Food Bag Recipes by Nadia Lim - My Food Bag',
    description: {
      description,
    },
    type: 'article',
    canonicalUrlLink: {
      absoluteUrl: '/late-sales',
    },
    sharingLinkImage: {
      id: '',
      __typename: '',
      alternateText: '',
      small: {
        id: '',
        file: {
          url: '',
        },
      },
      large: {
        id: '',
        file: {
          url: '',
        },
      },
    },
    allowIndexing: false,
    keywords: [],
  };

  return (
    <PageTemplate
      metadata={metadata}
      render={() => <SelfServeBuffer {...props} />}
    />
  );
};

export default WithPathRules(WithSiteMetadata(LateSalesPageTemplate));
